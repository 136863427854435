import {
    Image,
    List,
    ListContent,
    ListDescription,
    ListHeader,
    ListItem,
    TabPane,
} from 'semantic-ui-react'

const resources = [
    {
        name: 'Hasan Piker (hasanabi)',
        desc: 'Political Commentator',
        image: '/images/hasan-piker.png',
        url: 'https://www.twitch.tv/hasanabi'
    },
    {
        name: 'Jewish Voice for Peace',
        desc: 'Organization',
        image: '/images/jvp.jpg',
        url: 'https://twitter.com/jvplive'
    },
    {
        name: 'Mehdi Hasan',
        desc: 'Political Commentator',
        image: '/images/mehdi-hasan.jpg',
        url: 'https://twitter.com/mehdirhasan'
    },
    {
        name: 'Naomi Klein',
        desc: 'Canadian Activist',
        image: '/images/naomi-klein.jpg',
        url: 'http://naomiklein.org'
    },
    {
        name: 'Norman Finkelstein',
        desc: 'Political Scientist, Activist, son to Jewish Holocaust-survivor parents',
        image: '/images/norman-finkelstein.jpg',
        url: 'https://www.normanfinkelstein.com'
    },
    {
        name: 'Torah Jews',
        desc: 'Organization',
        image: '/images/torah-jews.jpg',
        url: 'https://twitter.com/TorahJews'
    },
    {
        name: 'Torah Judaism',
        desc: 'Organization',
        image: '/images/torah-judaism.jpg',
        url: 'https://twitter.com/TorahJudaism'
    },
]

const PalestineTabResources = {
    menuItem: { content: 'Resources', icon: 'linkify' },
    pane: <TabPane>
        <List divided>
            {resources.map(resource => <ListItem as='a' href={resource.url}>
                <Image src={resource.image} size='mini' />
                <ListContent>
                    <ListHeader>{resource.name}</ListHeader>
                    <ListDescription>{resource.desc}</ListDescription>
                </ListContent>
            </ListItem>)}
        </List>
    </TabPane>
}

export default PalestineTabResources