import { Button, Icon, Item, TabPane } from "semantic-ui-react"

const panes = [
    {
        name: "Palestine Children's Relief Fund (PCRF)",
        meta: 'Humanitarian Organization | Delivers medical and humanitarian aid to children in Palestine and the Middle East',
        url: 'https://www.pcrf.net',
        image: '/images/pcrf.jpg',
        desc: [
            <p>
                PCRF focuses on addressing the medical needs of Palestinian children by arranging free medical care, sponsoring medical missions, and providing essential supplies and treatments.
                The organization also supports capacity-building initiatives to improve local healthcare systems and ensure sustainable medical care for future generations.
            </p>
        ]
    },
    {
        name: "Palestine Red Crescent Society (PRCS)",
        meta: "Humanitarian Organization | Provides emergency medical services and health care to Palestinian communities",
        url: 'https://www.palestinercs.org',
        image: '/images/prcs.png',
        desc: [
            <p>
                PRCS is a humanitarian organization offering emergency medical services, health care, and disaster response to Palestinian communities.
                The organization operates in the West Bank, Gaza Strip, and Palestinian refugee camps in neighboring countries.
                PRCS is part of the International Red Cross and Red Crescent Movement and works to alleviate human suffering, promote health, and ensure the well-being of Palestinians through various health and social programs.
            </p>
        ]
    },
    {
        name: "Medical Aid for Palestinians (MAP)",
        meta: "Charity organization | Delivers health and medical care to Palestinians",
        url: 'https://www.map.org.uk',
        image: '/images/map.jpg',
        desc: [
            <p>
                UK-based charity organization that provides health and medical care to Palestinians living under occupation, as refugees, or in exile.
                MAP delivers emergency aid, supports local health systems, and advocates for the rights of Palestinians to health and dignity.
                The organization works in partnership with local health providers to address immediate health needs and promote long-term health development, focusing on maternal and child health, mental health, and chronic diseases.
            </p>
        ]
    },
    {
        name: 'United Nations Relief and Works Agency for Palestine Refugees in the Near East (UNRWA)',
        meta: 'United Nations Programme | Provide direct relief and works programmes for Palestinian refugees',
        url: 'https://www.unrwa.org',
        image: '/images/unrwa.png',
        desc: [
            <p>
                A UN agency that supports the relief and human development of Palestinian refugees.
                UNRWA's mandate encompasses Palestinians who fled or were expelled during the Nakba, the 1948 Palestine War, and subsequent conflicts, as well as their descendants, including legally adopted children.
            </p>,
            <p>
                UNRWA has faced significant accusations and attacks from Israel following the October 7 attacks.
                Israel's baseless allegations against UNRWA state the staff have ties to Hamas and have used agency facilities for terrorist activities.
                <b> This has lead to a reduction in international funding for the agency</b>.
                Many perceive these actions as an effort by Israel to undermine UNRWA's operations, thereby attempting to diminish the Palestinian right of return and exacerbating the humanitarian crisis in Gaza.
            </p>
        ]
    }
]

const PalestineTabDonate = {
    menuItem: { content: 'Donate', icon: 'money bill alternate online' },
    pane: <TabPane>
        <Item.Group divided>
            {panes.map(pane => <Item>
                <Item.Image src={pane.image} />
                <Item.Content>
                <Item.Header>{pane.name}</Item.Header>
                    <Item.Meta>
                        <span className='cinema'>{pane.meta}</span>
                    </Item.Meta>
                    <Item.Description>
                        {pane.desc}
                    </Item.Description>
                    <Item.Extra>
                        <Button primary floated='right' as='a' href={pane.url}>
                            Donate
                            <Icon name='right chevron' />
                        </Button>
                    </Item.Extra>
                </Item.Content>
            </Item>)}
        </Item.Group>
    </TabPane>
}

export default PalestineTabDonate