import React from 'react'
import {
    Container,
    Grid,
    Header,
    HeaderContent,
    HeaderSubheader,
    Image,
    Segment,
    Tab,
} from 'semantic-ui-react'
import PalestineTabAbout from './PalestineTabAbout'
import PalestineTabResources from './PalestineTabResources'
import PalestineTabDonate from './PalestineTabDonate'

const PalestinePage = () => (
    <div>
        <Grid centered container>
            <Grid.Column textAlign="center" width={15} style={{ marginTop: '2em' }}>
                <Header as='h2'>
                    <Image circular src='/favicon.ico' />
                    <HeaderContent>
                        Free Palestine
                        <HeaderSubheader>From the River to the Sea, Palestine will be Free!</HeaderSubheader>
                    </HeaderContent>
                </Header>
            </Grid.Column>
        </Grid>

        <Container style={{ marginTop: '3em' }}>
            <Tab panes={[PalestineTabAbout, PalestineTabResources, PalestineTabDonate]} renderActiveOnly={false} />
        </Container>

        <Segment vertical style={{ margin: '1em 0em 0em' }}>
        </Segment>
    </div>
)

export default PalestinePage