import {
    Accordion,
    AccordionTitle,
    Comment,
    CommentAuthor,
    CommentAvatar,
    CommentContent,
    CommentGroup,
    CommentMetadata,
    CommentText,
    Divider,
    Embed,
    Header,
    Icon,
    List,
    ListItem,
    Message,
    Segment,
    TabPane,
} from 'semantic-ui-react'

const sections = [
    {
        header: 'What does Free Palestine mean?',
        body: [
            <><i>"Free Palestine from the river to the sea"</i> calls for an end to apartheid and occupation and to ensure Palestinians have equal rights and representation.
                The louder media often incorrectly categorizes it as anti-semitic. The slogan does not mean the genocide of Jews similar to how "Black Lives Matter" does not imply that white lives don't matter.
                The Anti-Defamation League (ADL) and Israel often conflate anti-Zionism with anti-Semitism, suggesting Israel represents all Jews and enjoys unanimous support from them.
                This is not true and is itself an anti-Semitic notion.</>,
            <Message warning>
                <Accordion defaultActiveIndex={1} panels={[
                    {
                        title: <AccordionTitle icon='youtube'>
                            <Icon name='youtube' />
                            <u>WATCH: <b>Is Anti-Zionism Anti-Semitism?</b> Political commentator, Mehdi Hasan debates Zionist, Ilan Pappé.</u>
                        </AccordionTitle>,
                        content: {
                            content: <Embed id='K1VTt_THL4A' source='youtube' active={true} />
                        }
                    }
                ]} />
            </Message>
        ]
    },
    {
        header: 'Is Zionism part of Judaism?',
        body: [
            <CommentGroup size='small'>
                <Comment>
                    <CommentAvatar as='a' src='/images/norman-finkelstein.jpg' />
                    <CommentContent>
                        <CommentAuthor as='a' href='https://www.normanfinkelstein.com/british-zionism/'>Norman Finkelstein</CommentAuthor>
                        <CommentMetadata>
                            <span>Political scientist, born to parents who survived the Holocaust</span>
                        </CommentMetadata>
                        <CommentText>There's only one form of Zionism of any consequence today, either in Israel or in the Jewish diaspora: right-wing, exclusionary, discriminatory ethno-nationalism, inspired by religious messianism.</CommentText>
                    </CommentContent>
                </Comment>
            </CommentGroup>,
            <>Judaism is an Abrahamic religon that evolved from Yahwism 2500 years ago. Zionism is a 19th-century settler colonial nationalist movement to establish a Jewish state.
                Zionism is therefore not a part of Judaism. In fact, Christian and Hindu Zionists exist as well, and in larger number.
                Jews from across the globe have come forward to denounce Zionism. In doing so, <b>plenty of anti-Zionist Jews have been brutally assaulted by Zionists</b> in Israel.
                Yet, the bravery of our fellow Jewish brothers is commendable.</>,
            <Message warning>
                <Accordion defaultActiveIndex={1} panels={[
                    {
                        title: <AccordionTitle icon='youtube'>
                            <Icon name='youtube' />
                            <u>WATCH: IDF attacks Anti-Zionist Jews</u>
                        </AccordionTitle>,
                        content: {
                            content: (
                                <div>
                                    <i>Today [Nov 1, 2023], during a police raid to take down Palestinian flags in the anti-Zionist Jewish neighborhood, Mea Shearim in Jerusalem, the Israeli policemen were recorded attacking residents and knocking residents down on the road, hitting, and punching them in the face.
                                        Police violence against ultra-Orthodox Jews continues constantly, Zionists constantly attack Jews, this must stop.
                                        The only crime of these Jews is that they stand with the Palestinian people and are against Zionism.</i> ~ <a href="https://twitter.com/TorahJudaism/status/1719741630763159672">@TorahJudaism</a>
                                        <Divider/>
                                    <Embed url='/idf-attacking-anti-zionist-jews.mp4' active={true} />
                                </div>
                            )
                        }
                    }
                ]} />
            </Message>,
            <Message info>
                Below are resources to organizations led by our Jewish brothers against Israel's Zionist terrorism:
                <List as='ul'>
                    <ListItem as='li'><a href="https://twitter.com/jvplive">Jewish Voice for Peace</a></ListItem>
                    <ListItem as='li'><a href="https://twitter.com/TorahJews">Torah Jews</a></ListItem>
                    <ListItem as='li'><a href="https://twitter.com/TorahJudaism">Torah Judaism</a></ListItem>
                </List>
            </Message>
        ]
    }
]

const PalestineTabAbout = {
    menuItem: { content: 'About', icon: 'info' },
    pane: <TabPane>
        <CommentGroup size='small'>
            <Comment>
                <CommentAvatar as='a' src='/images/mandela.jpg' />
                <CommentContent>
                    <CommentAuthor as='a' href='https://www.pbs.org/newshour/world/nelson-mandelas-support-for-palestinians-endures-with-south-africas-genocide-case-against-israel'>Nelson Mandela</CommentAuthor>
                    <CommentMetadata>
                        <span>South African Anti-Apartheid Activist</span>
                    </CommentMetadata>
                    <CommentText>We know too well that our freedom is incomplete without the freedom of the Palestinians.</CommentText>
                </CommentContent>
            </Comment>
        </CommentGroup>

        {sections.map(section => <>
            <Header as='h4' attached='top'>{section.header}</Header>
            <Segment attached>{section.body}</Segment>
        </>)}
    </TabPane>
}

export default PalestineTabAbout